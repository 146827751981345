import styled from "styled-components";

export const HeroContainer = styled.div`
  height: auto;
  margin: 0;
  width: 100vw;
`;



 