import HeroImage1 from '../../assets/hero1.jpg';
import HeroImage2 from '../../assets/hero2.jpg';
import HeroImage3 from '../../assets/hero3.jpg';

export const sliderImage = [
  {
    title: "",
    description: "",
    urls: HeroImage1,
  },
  {
    title: "",
    description: "",
    urls: HeroImage2,
  },
  {
    title: "",
    description: "",
    urls: HeroImage3
  }
];