const topNav = [
  {id:1, label:'Home', href:'/'},
  {id:2, label:'Contato', href:'/contato'},
  {id:3, label:'Empreendimentos', href:'/empreendimentos'},
  {id:4, label:'Sobre', href:'/sobre'}
];

export const getTopNav = () => {
  return topNav;
}

